const NavDataList = [
    {
        id: 1,
        name: 'Sobre la empresa',
        link: '#about',
        active: false
    },
    {
        id: 2,
        name: 'Servicios',
        link: '#service',
        active: false
    },
    {
        id: 3,
        name: 'Projectos',
        link:'#project',
        active: false
    },
    {
        id: 4, 
        name: 'Contactanos',
        link: 'mailto: Admi.Soporte@livainn.com',
        active: false
    }
]

export default NavDataList;